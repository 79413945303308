import React from "react";
import Userdetails from "./Userdetails";
// import Anqlogo from "../images/anqlogo.svg";
import { Typography } from "@mui/material";

function Topbar() {
  return (
    // <div>
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "flex-start",
        padding: "0px 10px",
        color: "#00205c",
        fontWeight: "bolder",
      }}
    >
      <div
        style={{
          display: "inline-flex",
          flexDirection: "column",
          marginTop: "2px",
          alignItems: "center",
        }}
      >
        <Typography
          style={{
            padding: "1px 2px 1px 1px",
            fontSize: "12px",
            width: "70px",
            textAlign: "center",
          }}
        >
          Client
        </Typography>
        <Typography style={{ fontSize: "13px", textAlign: "center" }}>
          {sessionStorage.getItem("clientname")}
        </Typography>
      </div>
      <div
        style={{
          display: "inline-flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          style={{
            padding: "1px 2px 1px 1px",
            fontSize: "12px",
            width: "85px",
            textAlign: "center",
          }}
        >
          Project
        </Typography>
        <Typography style={{ fontSize: "13px", textAlign: "center" }}>
          {sessionStorage.getItem("projectname")}
        </Typography>
      </div>

      <div
        style={{
          display: "inline-flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          style={{
            padding: "1px 2px 1px 1px",
            fontSize: "12px",
            width: "85px",
            textAlign: "center",
          }}
        >
          Database
        </Typography>
        <Typography style={{ fontSize: "13px", textAlign: "center" }}>
          {process.env.REACT_APP_DB_NAME}
        </Typography>
      </div>
      {/* </div> */}
      {/* </div> */}

      {/* code commented as suggested by joyson sir <img
          src={Anqlogo}
          // alt="anqlogo.svg"
          style={{
            width: "auto",
            height: "50px",
            margin: "15px 3px 0px 25px",
            padding: "1px",
          }}
        />*/}
      <span>
        <Userdetails />
      </span>
    </div>
  );
}

export default Topbar;
