import React from "react";
import { TopNavBar } from "../Components/navbar";

const Withnavbar = (Component) => (props) => {
  return (
    <div>
      <TopNavBar />
      <Component />
    </div>
  );
};

export default Withnavbar;
