import React, { useState } from "react";
import Logger from "./Components/screens/Logger";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Apidocs from "./Components/screens/Apidocs";
import Longutldisplay from "./Components/screens/Longutldisplay";
import Fallbackui from "./Components/screens/Fallbackui";
import Homepagetabs from "./Components/Homepagetabs";
import { Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { Card, Stack } from "@mui/material";
import { TopNavBar } from "./Components/navbar";

function Fallback() {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        display: "flex",
        backgroundColor: "white",
      }}
    >
      <Card
        style={{
          width: "50%",
          height: "50%",
          margin: "auto",
          display: "flex",
          boxShadow: "0 0 15px white",
          borderRadius: "20px",
          backgroundColor: "rgba(206, 17, 38, 0.05)",
        }}
      >
        <Stack direction="row" spacing={10} alignItems="center">
          <ErrorIcon
            variant=""
            fontSize="large"
            color="error"
            style={{ margin: "auto 0 auto 15px" }}
          />
          <Typography
            color="gray"
            style={{
              margin: "auto 0 auto 30px",
              fontSize: "50px",
              fontFamily: "serif",
              fontStyle: "normal",
              fontWeight: "20%",
            }}
          >
            500 something went wrong
          </Typography>
        </Stack>
      </Card>
    </div>
  );
}
export default function App() {
  const [tool, setTool] = useState(false);
  React.useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    if (params.get("metadata_id") === null) {
      setTool(true);
    }
    console.log("hi", params.get("metadata_id"));
  }, []);
  return (
    <div>
      <Fallbackui>
        <Router>
          <Switch>
            <Route
              exact
              path={"/url/:id"}
              render={(props) => {
                return (
                  <>
                    <Longutldisplay id={props.match?.params.id} />
                  </>
                );
              }}
            ></Route>
            {tool ? (
              <Fallback />
            ) : (
              <>
                <Route exact path="/" component={Homepagetabs}></Route>
                <Route exact path="/Loggeer/:id" component={Logger}></Route>
                <Route exact path="/apid/:id" component={Apidocs}></Route>
              </>
            )}

            {/* <Route exact path='/Forgetpwd' component={Forgetpwd}></Route>
              <Route exact path='/Changepwd' component={Changepwd}></Route> */}
            {/* </Privateroute> */}
          </Switch>
        </Router>
      </Fallbackui>
    </div>
  );
}
